.carousel {
  .carousel-control-prev .carousel-control-prev-icon,
  .carousel-control-next .carousel-control-next-icon {
    display: inline-block;
    font: normal normal normal 1em/1 "Nucleo";
    vertical-align: middle;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    background-image: none;
  }
  .carousel-control-prev .carousel-control-prev-icon:before {
    content: "\ea34";
  }
  .carousel-control-next .carousel-control-next-icon:before {
    content: "\ea35";
  }
  .carousel-caption h3 {
    display: none;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #2d2d30;

  background: linear-gradient(
    to bottom,
    #0d0d10,
    #1d1d20,
    #212125
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  overflow: hidden;
  font-weight: 200;
  color: #e0e0e0;
  font-size: 0.6rem;
  user-select: none;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}
